import { Component, Input, signal } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LocationExtended } from '../../../../shared/services/locations.service';

@Component({
  selector: 'app-location-info',
  standalone: true,
  imports: [CommonModule, MatIconModule, NgClass],
  template: `
    @if (location) {
    <dl class="features-list" [ngClass]="{ 'full-width': isFullWidth }">
      <div class="full-flex">
        <dt class>
          <mat-icon>directions_walk</mat-icon>
          Activities:
        </dt>
        <dd class>{{ location.activities }}</dd>
      </div>
      <div class="full-flex">
        <dt class>
          <mat-icon>fitness_center</mat-icon>
          Difficulty:
        </dt>
        <dd class>{{ location.difficulty }}</dd>
      </div>
      <div class="full-flex">
        <dt class>
          <mat-icon>timer</mat-icon>
          Duration:
        </dt>
        <dd class>{{ location.duration }}</dd>
      </div>
      @if (estimatedDistance) {
      <div class="full-flex">
        <dt class>
          <mat-icon>straighten</mat-icon>
          <!-- <span class="desktop-only">Estimated</span> -->
          Distance from You:
        </dt>
        <dd class>{{ estimatedDistance }}</dd>
      </div>
      }
    </dl>
    }
  `,
  styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent {
  @Input() location?: Partial<LocationExtended>;
  @Input() estimatedDistance?: string;
  @Input() isFullWidth?: boolean;

  constructor() {}
}
