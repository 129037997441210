import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocationExtended } from '../../services/locations.service';

@Injectable({
  providedIn: 'root',
})
export class ImageIndexService {
  private locationSource = new Subject<LocationExtended>();
  currentLocation?: LocationExtended;

  locationChanged$ = this.locationSource.asObservable();

  setLocation(location: LocationExtended) {
    this.currentLocation = location;
    this.locationSource.next(location);
  }
}
